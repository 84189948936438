.side-toggle{
  position: fixed;
  z-index: 1;
  top: 90%;
  right: 18px;
  transform: translateY(-50%);
}

.side-toggle-btn{
  padding: 10px 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  background-color: $white-color;
  border-radius: 4px 0 4px 0;
  box-shadow: 0 0 10px rgba(0,0,0,0.15);
  white-space: nowrap;
}

.side-toggle-btn svg{
  font-size: 32px;
  margin: 0 10px;
}


@media(min-width: 768px) {
  .side-toggle{
    top: 50%;
    right: -72px;
    transform: translateY(-50%) rotate(-90deg);
  }

  .side-toggle-btn{
    padding: 10px 15px;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
  }

  .side-toggle-btn svg{
    font-size: 24px;
    margin: 0 5px 0 0;
  }
}