$white-color: #ffffff;
$grey-color: #f5f7fc;
$border-color: #E0E0E0;
$lightgrey-color: #CCCCCC;
$darkgrey-color: #888888;
$primary-color: #0d2881;
$dark-primary-color: #142749;
$secondary-color: #3567c1;
$secondary-text-color: #4B5563;
$danger-color: #e23e4e;
$dark-color: #333333;

$font-size-smallest: 10px;
$font-size-small: 12px;
$font-size: 14px;
$font-size-big: 16px;
$font-size-biggest: 18px;

$h1-size: 20px;
$h1-md-size: 24px;
$h1-lg-size: 28px;
$h1-xl-size: 32px;

$h2-size: 18px;
$h2-md-size: 20px;
$h2-lg-size: 22px;
$h2-xl-size: 24px;

$h3-size: 16px;
$h3-md-size: 18px;
$h3-lg-size: 20px;
$h3-xl-size: 22px;

$h4-size: 14px;
$h4-md-size: 16px;
$h4-lg-size: 18px;
$h4-xl-size: 20px;

$h5-size: 12px;
$h5-md-size: 14px;
$h5-lg-size: 16px;
$h5-xl-size: 18px;

$h6-size: 10px;
$h6-md-size: 12px;
$h6-lg-size: 14px;
$h6-xl-size: 16px;

$svg-size: 40px;
$svg-md-size: 40px;
$svg-lg-size: 40px;
$svg-xl-size: 40px;