/**
    FOOTER
 **/

footer{
  padding: 30px 0 30px;
  background-color: $dark-primary-color;
  color: $white-color;
}

footer a{
  color: $white-color;
}

.footer-header{
  margin-bottom: 12px;
  font-size: $font-size-big; // ***
  font-weight: 700;
  text-transform: uppercase;
}

.footer-body ul{
  padding: 0 20px 0 0;
  margin-bottom: 0;
}
.footer-body li{
  margin-bottom: 10px;
  list-style: none;
}

.copyright{
  margin-top: 30px;
  padding-top: 30px;
  font-size: $font-size;
  text-align: center;
  border-top: 1px solid $white-color;
}

@media(min-width: 768px) {
  footer{
    padding: 60px 0 30px;
  }
  .footer-header{
    margin-bottom: 20px;
  }

  .footer-body li{
    margin-bottom: 15px;
  }

  .footer-body li a{
    display: block;
    line-height: 1.25;
  }
}