.sign-in {
  box-shadow: 1px 1px 2px #3567C1,
  -1px -1px 2px #3567C1;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  transition: all ease .2s;
  font-size: 14px;
  font-weight: 500;

  svg {
    font-size: 20px !important;
  }

  svg + * {
    margin-left: 5px;
  }

  &:hover {
    background: #fff;
    transition: all ease .2s;
    color: $primary-color;
    a{
      color: $primary-color;
    }
  }
}

.mobile-header .sign-in {
  .sign-in {
    box-shadow: 1px 1px 2px $primary-color,
              -1px -1px 2px $primary-color;
  }
   a, svg {
     color: #000;
   }
}