@import "../global/variables";

.page-item{
  margin: 0 2px;

  .page-link{
    padding: 8px 15px;
    font-size: 16px;
    font-weight: 700;
    border: 0;
    color: $primary-color;
    border-radius: 4px;

    &:hover {
      background-color: $grey-color;
    }
  }

  &.active .page-link {
    background-color: $primary-color;
    color: $white-color;
  }
}

