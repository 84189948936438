.mobile-header {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  background: #000;
  overflow: hidden;
  transition: all 0.3s ease-out, background 0.5s ease-out;
  z-index: 100;
  box-shadow: 0 1px 55px rgba(54, 49, 53, 0.15);
}

.mobile-header .top-overlay {
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  width: 100%;
  background: #ffffff;
  position: absolute;
}

.mobile-header .burger-container {
  position: relative;
  display: inline-block;
  height: 60px;
  width: 60px;
  cursor: pointer;
  transform: rotate(0deg);
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.mobile-header .burger-container #burger {
  width: 24px;
  height: 8px;
  position: relative;
  display: block;
  margin: -4px auto 0;
  top: 50%;
}
.mobile-header .burger-container #burger .bar {
  width: 100%;
  height: 2px;
  display: block;
  position: relative;
  background: $primary-color;
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition-delay: 0s;
}
.mobile-header .burger-container #burger .bar.topBar {
  transform: translateY(0px) rotate(0deg);
}
.mobile-header .burger-container #burger .bar.btmBar {
  transform: translateY(6px) rotate(0deg);
}
.mobile-header .icon {
  display: inline-block;
  position: absolute;
  width: 60px;
  height: 60px;
  left: 55px;
  top: 0;
}
.mobile-header .icon.icon-logo {
  background: #fff;
  padding-left: 2px;
  border-radius: 40%;
}

.mobile-header .icon.icon-logo a {
  height: 100%;
  display: flex;
  align-items: center;
}
.mobile-header .icon.icon-logo a img {
  height: 35px;
}
.mobile-header .icon.icon-search {
  top: 0;
  left: auto;
  right: 10px;
  width: 120px;
  transform: translateX(0px);
  transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition-delay: 0.65s;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .lang-select{
    margin-bottom: 0 !important;
    margin-right: 15px;

    a{
      display: block;
      margin-top: 4px;
      margin-bottom: 0;
      font-size: 12px;
      color: $primary-color;
      border-color: $primary-color;
    }
  }
}
.mobile-header .icon.icon-search > a {
  margin-right: 1rem;
  color: $white-color;
  display: block;
  padding: 2px;
  border-radius: 5px;
}
.mobile-header .icon.icon-search > a.cart-toggle {
  font-size: 1.5rem;
  color: $white-color;
}
.mobile-header .icon.icon-search > a.cart-toggle > div {
  position: relative;
}
.mobile-header .icon.icon-search > a.cart-toggle .cart-total {
  position: absolute;
  top: -4px;
  right: -4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
  background-color: #FC9A42;
  color: #ffffff;
  border-radius: 50%;
}
.mobile-header .icon.icon-search > a.cart-toggle i {
  display: block;
}
.mobile-header .icon.icon-search > a.cart-toggle:hover, .mobile-header .icon.icon-search > a.cart-toggle:focus, .mobile-header .icon.icon-search > a.cart-toggle:active {
  text-decoration: none;
  opacity: 1;
}
.mobile-header ul.menu {
  position: relative;
  display: block;
  padding: 1rem 1.5rem;
  list-style: none;
}
.mobile-header ul.menu li.menu-item {
  margin-bottom: 10px;
  border-bottom: 2px solid $grey-color;
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
}
.mobile-header ul.menu li.menu-item > a {
  display: block;
  position: relative;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  line-height: 2.35;
  width: 100%;
}
.mobile-header ul.menu li.menu-item:last-child {
  border-bottom: 0;
}
.mobile-header ul.menu ul {
  padding: 10px 0;
}
.mobile-header ul.menu ul li a {
  display: block;
  margin-bottom: 10px;
  padding-right: 10px;
  font-weight: 500;
  line-height: 1.5;
  color: $white-color;
}
.mobile-header ul.menu ul li:last-child a {
  margin-bottom: 0;
}
.mobile-header.menu-opened {
  height: 100vh;
  position: fixed;
  overflow-y: auto;
  background-color: #292929;
  transition: all 0.3s ease-in, background 0.3s ease-in;
}

.mobile-header.menu-opened .burger-container {
  transform: rotate(90deg);
}
.mobile-header.menu-opened .burger-container #burger .bar {
  transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition-delay: 0.2s;
}
.mobile-header.menu-opened .burger-container #burger .bar.topBar {
  transform: translateY(6px) rotate(45deg);
}
.mobile-header.menu-opened .burger-container #burger .bar.btmBar {
  transform: translateY(4px) rotate(-45deg);
}
.mobile-header.menu-opened ul.menu li.menu-item {
  opacity: 1;
  padding-bottom: 10px;
}
.mobile-header.menu-opened .icon.icon-bag {
  transform: translateX(75px);
  transition-delay: 0.3s;
}
.mobile-header .search-form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: none;
  background-color: #f5f5f5;
  z-index: 115;
  box-shadow: 0 10px 55px 5px rgba(54, 49, 53, 0.15);
}
.mobile-header .search-form-outer {
  display: table;
  width: 100%;
  height: 100%;
}
.mobile-header .search-form-wrapper {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  padding: 0 1.5rem;
}
.mobile-header .search-form-inner {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}
.mobile-header svg {
  font-size: 16px;
  font-weight: bold;
  color: $white-color
}

.mobile-header .search-form-inner > svg {
  font-size: 20px;
  font-weight: bold;
  color: $white-color
}
.mobile-header .search-form-inner input {
  width: 85%;
  margin: 0;
  padding-left: 10px;
  font-size: 1rem;
  background-color: transparent;
  border: 0;
  box-shadow: none;
  outline: 0;
  outline-offset: 0;
  border-radius: 0;
}
.mobile-header .search-form-close {
  position: absolute;
  right: 0;
  top: calc(50% - 1px);
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: #ffffff;
  background-color: $primary-color;
  border-radius: 0.25rem;
  transform: translateY(-50%);
}
.mobile-header .search-form-close:hover, .mobile-header .search-form-close:focus, .mobile-header .search-form-close:active {
  text-decoration: none;
  opacity: 1;
}
.mobile-header .search-form-toggle svg {
  font-size: 24px;
}

.mobile-header .search-form-toggle.active .search-formopen {
  display: none;
}
.mobile-header .search-form-toggle.active .search-form__close {
  display: block;
}
.mobile-header .search-form-toggle:hover, .mobile-header .search-form-toggle:focus, .mobile-header .search-form-toggle:active {
  text-decoration: none;
  opacity: 1;
}
.mobile-header .search-form.active {
  display: block;
}