@import "@/styles/global/variables.scss";

/**
    @glidejs/glide
 */
.glide__slides {
  display: flex;
}
.glide__slide{
  height: auto !important;
}


.glide.glide__fade .glide__slides {
  // important needed because this is set using the style attribute
  transform: translate3d(0, 0, 0) !important;
}

.glide.glide__fade .glide__slide {
  position: absolute;
  top: 0;
  left: 0;
  order: 2;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}

.glide.glide__fade .glide__slide.glide__slide--active {
  position: relative;
  order: 1;
  opacity: 1;
  z-index: 1;
}


/**
    FROALA
 */
[data-f-id="pbf"]{
  display: none;
}


/**
    VUE DATEPICKER
 */
.dp__input{
  font-family: 'Inter', sans-serif;
  //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  line-height: 30px;
  padding: 7px 14px;
  border: 2px solid $border-color;
}
.dp__input_icon_pad{
  padding-left: 40px;
}

.vue-datepicker svg {
  box-sizing: content-box;
}
/**
    BOOSTRAP SELECT
 */
.bootstrap-select>.dropdown-toggle.bs-placeholder{
  color: $primary-color;

}
.bootstrap-select>.dropdown-toggle.bs-placeholder:active,
.bootstrap-select>.dropdown-toggle.bs-placeholder:focus,
.bootstrap-select>.dropdown-toggle.bs-placeholder:hover{
  color: $white-color;
}

.stretched-link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

/**
    FULL Calendar
 */

@media (max-width: 768px) {
  #calendar {
    font-size: 12px !important;
    width: 600px;
    overflow-x: auto;
  }
}

.fc-event{
  cursor: pointer;
}
.fc .fc-button-primary{
  background-color: $primary-color;
  border: 2px solid $primary-color;
}
.fc .fc-button-primary:disabled{
  background-color: $primary-color;
  border: 2px solid $primary-color;
}
.fc .fc-button-primary:hover{
  font-weight: 700;
  background-color: $white-color;
  border-color: $primary-color;
  color: $primary-color;
}
.fc-h-event{
  background-color: $primary-color;
  border: 0;
}
.fc-daygrid-event{
  padding: 5px;
  white-space: normal;
  border-radius: 4px;
}
.fc .fc-daygrid-event{
  margin-top: 5px;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end{
  margin-right: 5px;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-start{
  margin-left: 5px;
}
.fc .fc-button:disabled{
  opacity: 1;
}

.fc .fc-toolbar-title {
  font-size: $font-size-big;
}

@media (min-width: 768px) {
  .fc .fc-toolbar-title {
    font-size: $h2-md-size;
  }
}

/**
    VueNextSelect
 */

.icon.delete {
  min-width: 14px;
  min-height: 14px;
}

.dropdown-menu .dropdown-item.active {
  background-color: $primary-color;
  color: #fff;
}

.vue-select{
  width: 100%;
  padding: 10px 15px;
  font-weight: 500;
  background-color: $white-color;
  border-width: 2px;
  border-color: $border-color;
  border-radius: 4px;
  cursor: pointer;
}
.vue-select[data-addable=false] .vue-input input::placeholder {
  color: black !important;
}
.vue-select[data-removable=true] .vue-input input::placeholder {
  color: black !important;
}

input[readonly],
.vue-select[data-is-focusing='false'][aria-disabled='false'] .vue-input input{
  cursor: pointer;
}

.vue-tag.selected {
  color: #000;
  background-color: #fff;
  border: 1px solid #888888;
}

.vue-input {
  padding: 0;

  input {
    font-size: 16px;
    background: none;
  }

  input::placeholder {
    color: transparentize($darkgrey-color, 0.25);
  }
}

.icon.arrow-downward{
  border-color: $dark-color transparent transparent;
  color: $dark-color;
}
.vue-dropdown{
  z-index: 100;
  left: -2px;
  border: 2px solid $border-color;
  -webkit-box-shadow: 0 3px 10px rgba(0,0,0,0.15);
  -moz-box-shadow: 0 3px 10px rgba(0,0,0,0.15);
  box-shadow: 0 3px 10px rgba(0,0,0,0.15);
}
.vue-dropdown-item{
  padding: 10px 15px;
}
.vue-dropdown-item.highlighted{
  background: none;
}
.vue-dropdown-item.selected,
.vue-dropdown-item.selected.highlighted{
  background-color: $secondary-color;
  color: $white-color;
}
.vue-dropdown-item:hover{
  color: $primary-color;
}


/**
    VueNextSelect
 */
.bvi-active .bvi-body[data-bvi-images=false] img{
  display: none !important;
}
.bvi-panel {
  margin: 0 !important;
}
.bvi-blocks > .bvi-block:nth-child(4){
  display: none !important;
}
[data-bvi="modal"]{
  display: none !important;
}