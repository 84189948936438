/**
    MAIN NAV CONTAINER
 */
.main-nav__container {
  position: relative;
  height: 0;
  overflow: hidden;
  background: $dark-color;
  transition: height 0.3s;
}
.main-nav__container.show {
  height: 400px;
}
.main-nav__container .dropright {
  position: static;
}
.main-nav__container .dropright .dropdown-toggle::after {
  content: "\E9B2";
  top: 2px;
}
.main-nav__container .dropright .dropdown-menu {
  left: 16rem !important;
  transform: none !important;
  margin: 0;
  padding: 1rem 2rem;
  list-style: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
}
.main-nav__container .dropright .dropdown-menu li {
  padding-left: 0;
}
.main-nav__container .dropright .dropdown-menu li a {
  font-size: 1rem;
}
.main-nav__container .dropright .dropdown-menu li::before {
  display: none;
}
.main-nav__container .dropright .dropdown-menu li:last-child {
  margin-bottom: 0;
}
.main-nav__content {
  display: flex;
  align-items: center;
  color: #ffffff;
}
.main-nav__bg {
  width: 100%;
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent 50% 50% no-repeat;
  background-size: cover;
  opacity: 0.1;
  min-height: 450px !important;
  transition: all 0.5s;
}

.mobile-main-nav__bg {
  width: 100%;
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent 50% 50% no-repeat;
  background-size: cover;
  opacity: 0.1;
  min-height: 450px !important;
  transition: all 0.5s;
}


.main-nav__inner {
  width: 100%;
  padding: 4rem 0;
  position: relative;
  z-index: 2;
}
.main-nav__left, .main-nav__right {
  padding: 1rem 0;
}
.main-nav__left {
  padding-right: 3rem;
}
.main-nav__right {
  padding-left: 3rem;
  border-left: 1px solid #d7d7d7;
}
.main-nav__right ul {
  margin-bottom: 0;
}
.main-nav__right ul li {
  margin-bottom: 1rem;
}
.main-nav__right ul li a {
  font-size: 1rem;
  font-weight: 400;
  color: #ffffff;
}
.main-nav__right ul li:last-child {
  margin-bottom: 0;
}
.main-nav__right .card{
  background-color: $primary-color;
}
.main-nav__container .card::after {
  content: '';
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgb(2,0,36);
  background: linear-gradient(180deg, transparent 0%, $primary-color 100%);
}

.main-nav__container .card-live {
  overflow: hidden;
  cursor: pointer;
}

.main-nav__container .card-live::after{
  background: linear-gradient(180deg, transparent 0%, $danger-color 100%);
}

.main-nav__container .card-live img {
  transform: scale(1.33);
}

.main-nav__container .card-img-overlay{
  z-index: 2;
}

.main-nav__container .card-img-overlay h6{
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.main-nav__container .card-img-overlay svg{
  display: block;
  margin-right: 15px;
  font-size: 32px;
}
