/**
    SECTIONS
**/
.section{
  padding: 15px 0;
}

.section-tools + .section-header{
  margin-top: 60px;
}

.section-header h3 + h3{
  margin-left: 30px;
}

.section-header h3[role="tab"]{
  cursor: pointer;
}

.section-header h3[role="tab"]:not(.active){
  color: $darkgrey-color;
}

.section-header a:not(.btn):not(.section-title-link){
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
.section-header a svg{
  display: block;
  margin-left: 5px;
  font-size: 16px;
}
.section-header a:not(.btn):not(.section-title-link):hover{
  color: $secondary-color;
}


/**
    SECTION TOOLS
**/

.section-tools{
  padding: 15px;
  background: $grey-color;
  border-radius: 4px;
}
.section-tools-wrapper{
  //overflow-x: auto;
}
.section-tools-wrapper::-webkit-scrollbar{
  display: none;
}
.section-tools-lg{
  padding: 30px;
}
.section-tools h6{
  margin-bottom: 15px;
  font-weight: 500;
}
.section-tools .btn{
  padding-left: 15px;
  padding-right: 15px;
  text-align: left;
  box-shadow: 0 0 30px rgba(0,0,0,0.05);
}
.section-tools > div > div{
  margin: 2px 0;
}
.section-tools-lg > div > div{
  margin: 0;
}
.section-tools .dropdown{
  position: relative;
}
.section-tools .dropdown-toggle{
  white-space: normal;
}
.section-tools .dropdown-menu{
  width: 100%;
  box-shadow: 0 3px 20px rgba(0,0,0,0.25);
}

.section-tools .bootstrap-select .dropdown-toggle{
  white-space: nowrap;
}

.section-tools .bootstrap-select .dropdown-menu{
  max-height: 240px !important;
}

.section-tools .dropdown-item{
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 500;
  border-bottom: 1px solid $border-color;
}
.section-tools .dropdown-menu li:last-child .dropdown-item{
  border-bottom: 0;
}
.section-body{
  article{
    p {
      font-family: 'Inter', sans-serif !important;
      //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
    }
  }
}

.section-header-actions a{
  display: flex;
  margin: 5px 0;
  align-items: center;
}
.section-header-actions a:not(.active):not(.btn){
  color: $darkgrey-color;
}

.section-footer{
  padding: 30px 0;
}

@media(min-width: 768px) {
  .section{
    padding: 30px 0;
  }
  .section-tools > div > div{
    margin: 0 5px;
  }
}

@media(min-width: 1200px) {
  .section{
    padding: 60px 0;
  }
  .section-tools > div > div{
    margin: 0 5px;
  }
}

@media(min-width: 1400px) {

}