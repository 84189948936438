//@import "~vue-next-select/dist/index.css";


/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


html, body {
  font-family: 'Inter', sans-serif !important;
  //font-family:  -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: $font-size-big;
  line-height: 1.5;
  color: $dark-color;
}

a, a:hover, a:active, a:focus {
  text-decoration: none;
  color: inherit;
  outline: none;
}

a {
  color: $primary-color;
  background-color: transparent;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block;
}
button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button, input, select, textarea {
  max-width: 100%;
}
input[type="search"] {
  -webkit-appearance: textfield;
}
//input::placeholder {
//  color: $dark-color;
//}
button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button{
  font-family: "Inter", sans-serif !important;
  font-weight: 700;
  text-transform: uppercase;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}
p{
  margin-bottom: 15px;
}

nav ul, nav ol {
  list-style: none;
}

.row{
  --bs-gutter-x: 30px;
}

.wrapper {
  min-height: 30vh;
}

.cursor-pointer {
  cursor: pointer;
}

/**
    COLORS
 */
.bg-primary{
  background-color: $primary-color !important;
}
.bg-grey{
  background-color: $grey-color !important;
}
.bg-danger{
  background-color: $danger-color !important;
}
.text-primary{
  color: $primary-color !important;
}
.text-danger{
  color: $danger-color !important;
}
.border-danger{
  border-color: $danger-color !important;
}
.text-transform-none{
  text-transform: none !important;
}

/**
    BUTTONS
 */
.btn{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  border-width: 2px;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 4px;

  @media(min-width: 768px) {
    font-size: $font-size;
  }
}
.btn-sm{
  padding: 10px 20px;
  font-size: 12px;
}
.btn-xs{
  padding: 10px 15px;
  font-size: 10px;
}
.btn svg{
  display: inline-block;
  margin-left: 5px;
}
.btn-primary{
  background-color: $primary-color;
  border-color: $primary-color;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus{
  background-color: $secondary-color;
  border-color: $secondary-color;
  color: $white-color;
}
.btn-light{
  background-color: $white-color;
  border-color: $white-color;
  color: $primary-color;
}
.btn-light:hover,
.btn-light:active,
.btn-light:focus{
  background-color: $secondary-color;
  border-color: $secondary-color;
  color: $white-color;
}
.btn-light.active{
  background-color: $primary-color;
  border-color: $primary-color;
  color: $white-color;
}
.btn-outline-primary{
  color: $primary-color;
  border-color: $primary-color;
}
.btn-outline-primary:hover,
.btn-outline-primary:focus{
  background-color: $secondary-color;
  color: $white-color;
  border-color: $secondary-color;
}
.btn-outline-danger{
  color: $danger-color;
  border-color: $danger-color;
}
.btn-outline-danger:hover,
.btn-outline-danger:focus{
  background-color: $danger-color;
  color: $white-color;
  border-color: $danger-color;
}


.btn-header{
  display: flex;
  align-items: center;
  padding: 4px 10px 8px;
  font-size: 28px;
}

.btn-live{
  color: $danger-color;
  border-color: $danger-color;
  font-size: $font-size-smallest !important;
  padding: 10px 12px;
}

.btn-live.broadcast {
  position: relative;
  padding-right: 25px;
  transition: padding ease .2s;
}

.btn-live.broadcast::after{
  position: absolute;
  content: "";
  top: 50%;
  right: 10px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(226, 62, 78, 0.9);
  animation: pulse 1s infinite;
  box-shadow: 0 0 0 0 rgba(226, 62, 78, 0.5);
}

.btn-live.broadcast.broadcast__top::after {
  top: -7px;
  left: 50%;
  transform: translateX(-50%) !important;
}

.btn-live.broadcast:hover {
  padding-right: 10px;
  transition: padding ease .2s;
}

.btn-live.broadcast:hover::after {
  animation: none;
  display: none;
}

@keyframes pulse {
  0% {
    transform: translateY(-50%) scale(0.9);
  }
  70% {
    transform: translateY(-50%) scale(1);
    box-shadow: 0 0 0 10px rgba(90, 153, 212, 0);
  }
  100% {
    transform: translateY(-50%) scale(0.9);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}

.btn-esi img{
  width: 16px;
  margin-right: 10px;
}


/**
    FORM
 */
/**
    FORM
 */
.form-control, .form-select{
  padding: 10px 15px;
  font-weight: 500;
  color: $dark-color;
  border-width: 2px;
  border-color: $border-color;
  border-radius: 4px;
}
.form-control:focus{
  border-color: $secondary-color;
  box-shadow: none;
}
.form-control::placeholder {
  color: transparentize($darkgrey-color, 0.25);
}
.input-group-text{
  border-width: 2px;
  border-radius: 4px;
}
.input-group-text svg {
  display: block;
  font-size: 20px;
}
.form-control + .input-group-text{
  margin-left: 0 !important;
  border-left: 0;
}
.form-select{
  padding: 10px 15px;
  border-color: $border-color;
}
.form-check{
  margin-bottom: 10px;
}
.form-check-input:checked{
  background-color: $primary-color;
  border-color: $primary-color;
}
.form-label{
  display: flex;
  align-items: center;
  color: #888888;
  //font-weight: 700;
  //font-size: 12px;
  //line-height: 20px;
  //text-transform: uppercase;

  svg{
    display: block;
    margin-left: 10px;
    width: 20px;
    height: 20px;
    fill: $darkgrey-color;
  }
}
.custom-file-button input[type=file] {
  font-size: $font-size;
  margin-left: -2px !important;
  color: transparent;
}

.custom-file-button input[type=file]::-webkit-file-upload-button {
  display: none;
}

.custom-file-button input[type=file]::file-selector-button {
  display: none;
}

.custom-file-button label{
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: $border-color;
}
.custom-file-button label svg{
  margin-right: 5px;
  font-size: 20px;
}
.custom-file-button:hover label {
  background-color: $border-color;
  cursor: pointer;
}
.custom-file-button input[type=file]::after {
  content: attr(data-empty-text);
  position: absolute;
  left: 15px;
  font-size: $font-size;
  color: $dark-color;
}

/**
    DROPDOWN
 */
.dropdown-toggle::after{
  display: none;
}
.dropdown-toggle svg{
  font-size: $font-size-small;
}
.dropdown-menu{
  border: 0;
  border-radius: 4px;
  box-shadow: 0 3px 10px rgba(0,0,0,0.15);
}
.dropdown-item:hover{
  background: none;
  color: $primary-color;
}
.dropdown-item{
  white-space: normal;
}


/**
    CARD
 */
.card{
  border-color: $border-color;
  border-radius: 4px;
}
.card-pinned{
  background-color: $primary-color;
  color: $white-color;
}
.card-pinned svg{
  font-size: 24px;
}

.card-subtitle svg{
  margin-right: 10px;
  font-size: 24px;
}



/**
    PROGRESS
 */
.progress {
  width: 80px;
  height: 80px !important;
  line-height: 80px;
  margin-top: 10px;
  background: none;
  box-shadow: none;
  position: relative;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 6px solid $lightgrey-color;
    position: absolute;
    top: 0;
    left: 0;
  }

  >span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 6px;
    border-style: solid;
    position: absolute;
    top: 0;
  }

  .progress-value {
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: $white-color;
    font-size: 14px;
    font-weight: 500;
    color: $dark-color;
    line-height: 75px;
    text-align: center;
    position: absolute;
    top: 5%;
    left: 5%;
    //-webkit-box-shadow: inset 0 0 30px rgba(0,0,0,0.25);
    //-moz-box-shadow: inset 0 0 30px rgba(0,0,0,0.25);
    //box-shadow: inset 0 0 30px rgba(0,0,0,0.25);
  }

  .progress-left {
    left: 0;

    .progress-bar {
      left: 100%;
      border-top-right-radius: 80px;
      border-bottom-right-radius: 80px;
      border-left: 0;
      -webkit-transform-origin: center left;
      transform-origin: center left;
    }
  }

  .progress-right {
    right: 0;

    .progress-bar {
      left: -100%;
      border-top-left-radius: 80px;
      border-bottom-left-radius: 80px;
      border-right: 0;
      -webkit-transform-origin: center right;
      transform-origin: center right;
      animation: loading-1 1.8s linear forwards;
    }
  }

  &.danger{
    .progress-bar {
      border-color: $danger-color;
    }

    .progress-left{
      .progress-bar {
        animation: loading-2 1.5s linear forwards 1.8s;
      }
    }
  }
}

@keyframes loading-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
@keyframes loading-2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(80deg);
  }
}


/**
    MODAL
 */
.modal-footer{
  padding: 15px 30px;
}
.modal-header,
.modal-body{
  padding: 30px;
}
.modal-body{
  article{

    * {
      font-family: 'Inter', sans-serif !important;
      font-size: 16px !important;
    }

    p{
      font-family: 'Inter', sans-serif !important;
      font-size: 16px !important;
      line-height: 1.5 !important;
      a{
        position: relative;
        font-weight: 500;
        text-decoration: underline;

        &::after{
          content: '>>>';
          margin-left: 5px;
        }
      }
    }
    table{
      margin: 0 !important;
      border: 1px solid $border-color;

      th, td{
        border: 1px solid $border-color;
      }
    }
  }
}
.modal-header{
  &-actions{
    .btn-close{
      padding: 0;
      margin: 0;
    }
    .btn-hide{
      display: block;
      margin-right: 10px;
      width: 16px;
      height: 16px;
      border-bottom: 2px solid rgba(0,0,0,0.5);
    }
  }
}


/**
    DIVIDER
 */
.divider{
  width: 4px;
  height: 4px;
  background-color: $dark-color;
}
.light-divider{
  background-color: $white-color;
}


/**
    BREADCRUMB
 */

.breadcrumb {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
}

.breadcrumb::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.breadcrumb-item{
  font-size: $font-size;
  font-weight: 500;
}


/**
    CONTAINER
 */
.container-xxxl
{
  max-width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}

@media(min-width: 2000px) {
  .container-xxxl
  {
    max-width: 1920px;
  }
}


h1{font-size: $h1-size}
h2{font-size: $h2-size}
h3{font-size: $h3-size}
h4{font-size: $h4-size}
h5{font-size: $h5-size}
h6{font-size: $h6-size}

@media(min-width: 768px) {
h1{font-size: $h1-md-size}
h2{font-size: $h2-md-size}
h3{font-size: $h3-md-size}
h4{font-size: $h4-md-size}
h5{font-size: $h5-md-size}
h6{font-size: $h6-md-size}
}

@media(min-width: 992px) {
h1{font-size: $h1-lg-size}
h2{font-size: $h2-lg-size}
h3{font-size: $h3-lg-size}
h4{font-size: $h4-lg-size}
h5{font-size: $h5-lg-size}
h6{font-size: $h6-lg-size}
}

@media(min-width: 1200px) {
h1{font-size: $h1-xl-size}
h2{font-size: $h2-xl-size}
h3{font-size: $h3-xl-size}
h4{font-size: $h4-xl-size}
h5{font-size: $h5-xl-size}
h6{font-size: $h6-xl-size}
}