@media print {
  body, .wrapper {
    margin: 0 !important;
    padding: 0 !important;
  }
  @page {
    margin: 5% !important;
    padding: 0;
  }
  .print-hide {
    display: none !important;
  }
  .container,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12{
    padding: 0 !important;
    width: 100% !important;
  }
  .mt-1,
  .mt-2,
  .mt-3,
  .mt-4,
  .mt-5{
    margin-top: 0 !important;
  }
  .section{
    padding: 30px 0;
  }
  .section-post{
    padding-bottom: 30px;
  }
  .section-post + .section-post{
    padding-top: 30px;
  }
}