@import "../../global/variables";

/* Links */
.section-links .card{
  height: 100%;
  border-color: $grey-color;
}
.section-links .card-body{
  padding: 20px 80px 20px 20px;
}
.section-links .card svg{
  right: 10px;
  font-size: 32px;
  color: $primary-color;
}

@media (min-width: 768px) {
  .section-links .card svg{
    right: 10px;
    font-size: 64px;
    color: $primary-color;
  }
}