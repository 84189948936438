
.page-actions .page-action-item {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.page-actions .page-action-item:not(:last-child) {
    margin-bottom: 10px;
}

.page-actions .action-print a{
    display: flex;
    align-items: center;
    position: relative;
    color: #888888;
}

.page-actions .action-print a:hover,
.page-actions .action-print a:active
{
    color: #888888;
}

.page-actions .action-print a:hover::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: #888888;
    bottom: -3px;
    left: 0;
}

.page-actions .action-print svg{
    display: block;
    margin-left: 6px;
    font-size: 24px;
    color: #888888;
}
.page-actions .action-share a{
    position: relative;
    margin: auto 4px;
    text-align: center;
    padding: 0 2px;
}

.page-actions .action-share a:last-child{
    margin-right: 0;
}

.page-actions .action-share a:hover::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: #888888;
    bottom: -3px;
    left: 0;
}

.page-actions .action-share svg{
    display: block;
    font-size: 24px;
    color: #888888;
    margin: auto;
}

