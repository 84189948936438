header{
  width: 100%;
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  background-color: $white-color;
  box-shadow: 0 3px 20px rgba(0,0,0,0.25);
}
header .navbar{
  padding: 10px 0;
  font-weight: 500;
}
.navbar-brand > *{
  display: block;
}
.navbar-brand img{
  height: 40px;
}

.navbar-brand span{
  margin-left: 20px;
  font-size: $font-size;
  font-weight: 700;
  text-transform: uppercase;
}
header .navbar-nav{
  margin-right: 30px;
}
header .navbar-expand-md .navbar-nav .nav-link{
  display: flex;
  align-items: center;
  padding: 10px 5px;
  color: $dark-color;
  cursor: pointer;
  font-size: 14px;
}

header .navbar .nav-link svg{
  display: block;
  margin-left: 4px;
}
.navbar .dropdown-menu{
  max-width: 320px;
  min-width: 270px;
  padding: 20px;
}
.navbar .dropdown-menu li{
  margin-bottom: 15px;
  border-bottom: 1px solid $border-color;
}
.navbar .dropdown-menu li:hover{
  border-bottom-color: $primary-color;
}
.navbar .dropdown-item{
  padding: 0 0 5px;
  font-size: $font-size;
}
.navbar .dropdown-menu li:last-child{
  margin-bottom: 0;
  border: 0;
}
.navbar .dropdown-menu li:last-child a{
  padding-bottom: 0;
}

header{
  .search-form{
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    //height: 100%;
    display: none;
    background-color: $grey-color;
    z-index: 115;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);

    &.active {
      display: block;
    }
  }

  .search-form-outer {
    display: table;
    width: 100%;
    height: 100%;
  }

  .search-form-wrapper {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    padding: 10px 20px;
  }

  .search-form-inner {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;

    & > svg {
      font-size: 24px;
      color: $primary-color;
    }

    input {
      width: 78%;
      margin: 0;
      padding: 10px 30px;
      font-size: 16px;
      color: $dark-color;
      background-color: transparent;
      border: 0;
      box-shadow: none;
      outline: 0;
      outline-offset: 0;
      border-radius: 0;

      &:focus{
        background: none;
      }
    }
  }

  .search-form-actions{
    position: absolute;
    right: 0;
    top: calc(50% - 1px);
    transform: translateY(-50%);
  }

  .search-form-close {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: $white-color;
    background-color: $primary-color;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;

    &:hover,
    &:focus,
    &:active{
      background-color: $secondary-color;
    }
  }
}

@media (min-width: 768px) {
  header .navbar{
    padding: 15px 0;
  }

  .navbar-brand img{
    height: 64px;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 10px;
    padding-left: 10px;
  }
  header .navbar-expand-md .navbar-nav .nav-link{
    font-size: 16px;
    padding: 10px 15px;
  }
}

