@import "@/styles/global/variables";

/* Modules */
.section-modules {
  .card {
    border: 2px solid $primary-color;
    background-color: $grey-color;
    height: 100%;
    overflow: hidden;
    color: $primary-color;
    transition: all 0.25s ease;

    &:hover {
      background-color: $primary-color;
      color: $white-color;

      img.default-version {
        display: none;
      }

      img.hover-version {
        display: inline;
      }
    }

    .card-body {
      padding: 30px 100px 30px 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h5 {
        font-size: 14px;
        text-transform: uppercase;
      }

      h6 {
        margin-top: 10px;
        font-size: 12px;
      }

      svg, img {
        position: absolute;
        top: 50%;
        right: 24px;
        font-size: 32px;
        opacity: 0.25;
        transform: translateY(-50%);
      }

      @media(min-width: 768px) {
        svg, img {
          font-size: 60px;
        }
      }
    }
  }

  .module-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 10px;

    &.items-count-5,
    &.items-count-6 {
      @media (min-width: 992px) and (max-width: 1200px) {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      }

      @media (min-width: 1200px) {
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      }
    }

    @media (min-width: 768px) and (max-width: 1200px) {
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }

    @media (min-width: 1200px) {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
  }

  @media (min-width: 1200px) {
    .module-list.items-count-5 .card h5,
    .module-list.items-count-6 .card h5 {
      font-size: 12px;
      text-transform: uppercase;
      line-height: 18px;
    }

    .module-list.items-count-5 .card-body,
    .module-list.items-count-6 .card-body {
      padding: 15px 40px 15px 15px;
    }

    .module-list.items-count-5 .card img,
    .module-list.items-count-6 .card img {
      font-size: 32px;
      right: 10px;
    }
  }

  @media (min-width: 1440px) {
    .module-list.items-count-5 .card-body,
    .module-list.items-count-6 .card-body {
      padding: 20px 64px 20px 20px;
    }

    .module-list.items-count-5 .card img,
    .module-list.items-count-6 .card img {
      font-size: 32px;
      right: 10px;
    }
  }
}
