.top-bar{
  padding: 10px 0;
  color: $white-color;
}
.top-bar a{
  display: block;
  font-size: 12px;
  color: $white-color;
}
.top-bar .divider{
  margin: 0 15px;
}
.top-bar svg{
  display: block;
  font-size: 24px;
}

.top-bar .widgets{
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.top-bar .widgets .widget:not(:last-child){
  margin-right: 15px;
}

/**
    ICONS
 */
.social-icons a{
  display: block;
  padding: 5px;
  margin: 0 5px;
  background-color: $secondary-color;
  border-radius: 4px;
  transition: all 0.25s ease;
}

.social-icons a svg{
  display: block;
  font-size: 20px;
  color: $white-color;
  transition: all 0.25s ease;
}
.social-icons a:hover{
  background-color: $white-color;
}
.social-icons a:hover svg{
  color: $primary-color;
  g {
    fill: $primary-color;
  }
}



/**
    Sign in
 */

.sign-in{
  padding: 5px 3px;

  @media(min-width: 992px) {
    padding: 0;
  }
}

.sign-in .dropdown-menu a{
  color: $dark-color;
}

.sign-in .dropdown-menu a:hover{
  color: $primary-color;
  text-decoration: underline;
}

.sign-in .dropdown-menu{
  width: 100%;
  box-shadow: 0 3px 20px rgba(0,0,0,0.25);
  overflow: hidden;
}

.sign-in .dropdown-toggle,
.sign-in .sign-in__item
{
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.sign-in .dropdown-menu{
  min-width: 150px !important;
  max-height: 240px !important;
  left: -50%;
}

.sign-in .dropdown-item{
  color: $primary-color;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 500;
  border-bottom: 1px solid $border-color;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.sign-in .dropdown-menu li:last-child .dropdown-item{
  border-bottom: 0;
}