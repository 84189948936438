audio::-webkit-media-controls-panel {
  background-color: #fff;
  border-radius: 0;
}

audio::-webkit-media-controls-mute-button {
  background-color: #F5F7FC;
  border-radius: 50%;
}

audio::-webkit-media-controls-play-button {
  background-color: #F5F7FC;
  box-shadow: 2px 2px 5px #fff;
  border-radius: 50%;
}

audio::-webkit-media-controls-rewind-button {
  background: red;
  border: 2px solid teal;
}

