
.page-actions .page-action-item {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.page-actions a{
    display: flex;
    margin: 5px 0;
    align-items: center;
}

.page-actions a:hover{
    text-decoration: underline;
}

.page-actions svg{
    display: block;
    margin-left: 10px;
    font-size: 24px;
}
.page-actions a:not(.active):not(.btn){
    /*color: $darkgrey-color;*/
    color: #888888;
}
