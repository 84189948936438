.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  box-shadow: none;
  opacity: 1;
}


.lang-select {
  display: flex;
}

#mobile-header .lang-select {
  margin-bottom: 20px;
}

.lang-select__item {
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 7px;
  color: #fff;
  padding: 1px 5px;
  position: relative;
  border-radius: 2px;
  transition: background-color .5s;
  -webkit-transition: background-color .5s;
  -o-transition: background-color .5s;
  -moz-transition: background-color .5s;
  opacity: .65;
  line-height: 20px;
}


.lang-select__item:hover,
.lang-select__item.active {
  color: #fff;
  opacity: 1;
  border-bottom: 2px solid #fff;
  user-select: none;
}

.lang-select__item img {
  width: 18px;
  margin: 0;
  vertical-align: middle;
  line-height: 0;
}